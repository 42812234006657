import type { ReactNode } from 'react';
import React from 'react';

import type { DialogProps } from '@sravni/react-design-system/dist/types/components/Dialog';
import { useBoolean } from '@sravni/react-utils';

interface Props {
  renderComponent: (onClick: () => void) => ReactNode;
  renderDialog: (props: Pick<DialogProps, 'visible' | 'onClose'>) => ReactNode;
}

export const ComponentWithDialog = ({ renderComponent, renderDialog }: Props) => {
  const [isOpen, setIsOpen] = useBoolean(false);

  return (
    <>
      {renderComponent(setIsOpen.on)}

      {renderDialog({ visible: isOpen, onClose: setIsOpen.off })}
    </>
  );
};

import type { FC } from 'react';
import React, { memo } from 'react';

import { Typography } from '@sravni/react-design-system';

import { TERMS_LINK } from './PhoneNumberCheckerLayout.config';
import styles from './PhoneNumberCheckerLayout.module.scss';
import { phoneNumberCheckerLayout } from './PhoneNumberCheckerLayout.text';

const { Text, Heading, Link } = Typography;

interface Props {
  termsLink?: string;
}

export const PhoneNumberCheckerLayout: FC<Props> = memo(({ termsLink, children }) => (
  <div className={styles.container}>
    <Heading level={4}>{phoneNumberCheckerLayout.title}</Heading>

    {children}

    <Text className={styles.footerText} size={10}>
      {phoneNumberCheckerLayout.privacyAgreement}

      <Link href={termsLink} target="_blank" rel="noreferrer">
        &nbsp;{phoneNumberCheckerLayout.termsLink}
      </Link>
    </Text>
  </div>
));

PhoneNumberCheckerLayout.displayName = 'PhoneNumberCheckerLayout';

PhoneNumberCheckerLayout.defaultProps = {
  termsLink: TERMS_LINK,
};

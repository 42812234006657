import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import type { ListKeys } from '@src/constants/microcredits';
import { fetchMoreOffersAB } from '@src/reducers/microcredits';

export const useLoadRestOffers = (key: ListKeys) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMoreOffersAB({ key, init: true }));
  }, [dispatch, key]);
};

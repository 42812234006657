import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { makeMainGEvent, pushEvent } from '@src/utils/analytics';
import { MICROCREDITS_EVENT_CATEGORY } from 'entities/Microcredits/config';

export const openBottomSheetCalculator = (offer: IClientMicrocreditListItem, source: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: MICROCREDITS_EVENT_CATEGORY,
      eventAction: 'Подробнее о бонусах сравни из модального окна с кнопкой перехода в полную сделку',
      eventLabel: `${offer.organization.name}|${offer.name}|${offer._id}|${source}`,
      eventValue: undefined,
    }),
  );
};

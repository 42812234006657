import { BFF_PROXY_API } from '@src/shared/constants/apiRoutes';
import { getYandexMetricsIdJS } from '@src/utils/analytics';
import { requestInstance } from 'shared/api/requestInstance';

interface Data {
  phone: string;
  userId: string;
}

export const saveUserPhone = (data: Data) => {
  const clientIdYm = getYandexMetricsIdJS(false) || null;

  return requestInstance.post(BFF_PROXY_API.saveUserPhone, { ...data, clientIdYm });
};

import { Button } from '@sravni/react-design-system';
import type { Seo } from '@sravni/types/lib/seo';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { CommonCardProps } from '@src/@types/commonCardProps';
import Card from '@src/components/ProductList/components/Card';
import { isBezProtsentovPage, isProverennyeZaymyPage } from '@src/utils/routing';
import { IS_PROMO_FEATURE_ENABLED } from 'entities/Promo/lib/getPromoVariant';
import { openBottomSheetCalculator } from 'features/Microcredits/lib';
import { isFullDealOrganization } from 'shared/config/fullDealOrganizationIds';
import { ComponentWithDialog } from 'shared/ui/ComponentWithDialog';
import { FullDealCalculatorBottomSheet } from 'widgets/FullDealEntryPoint';

import { isOfferWithBottomSheetCalculator } from './isOfferWithBottomSheetCalculator';

function isShowCustomActions(offer: IClientMicrocreditListItem, path: string) {
  if (isBezProtsentovPage(path)) {
    return isOfferWithBottomSheetCalculator(offer._id);
  }
  if (isProverennyeZaymyPage(path)) {
    return false;
  }

  return IS_PROMO_FEATURE_ENABLED ? isFullDealOrganization(offer.organization._id) : false;
}

export const getRenderCard = (cardProps: CommonCardProps, path: string, seo: Partial<Seo>, isMobile: boolean) => {
  if (isMobile) {
    const withCustomActions = isShowCustomActions(cardProps.offer, path);

    if (withCustomActions) {
      return (
        <ComponentWithDialog
          renderComponent={(onClick) => (
            <Card
              {...cardProps}
              onContentClickCapture={(e) => {
                e.stopPropagation();

                onClick();
                openBottomSheetCalculator(cardProps.offer, 'bezprotsentov');
              }}
              actionButton={
                <Button
                  variant="primary"
                  size={36}
                  block
                  onClick={() => {
                    onClick();
                    openBottomSheetCalculator(cardProps.offer, 'bezprotsentov');
                  }}
                >
                  Получить
                </Button>
              }
            />
          )}
          renderDialog={(props) => (
            <FullDealCalculatorBottomSheet path={path} heading={seo?.heading} {...props} {...cardProps} />
          )}
        />
      );
    }
  }

  return <Card {...cardProps} />;
};

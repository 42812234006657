import type { ParsedUrlQuery } from 'querystring';

import type { IPublicUser } from '@sravni/types/lib/auth';

import { FILTERS_NAMES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import type { IFilter } from '@src/hooks/useFilters';
import { isMainPage, isNaKartuPage } from '@src/utils/routing';
import { AmountFilter } from 'features/AmountFilter';
import { PhoneNumberChecker } from 'features/PhoneNumberChecker';
import { ProductListFilterSortPanel } from 'widgets/ProductListFilterSortPanel';

export const getListPageFilterComponent = (
  path: string,
  query: ParsedUrlQuery,
  filters: IFilter,
  user: IPublicUser,
) => {
  const isNaKartu = isNaKartuPage(path);
  const isMain = isMainPage(path, query);

  if (isNaKartu) {
    return (
      <ProductListFilterSortPanel
        initialAmount={filters.filters.amount}
        initialTerm={filters.filters.term}
        sorting={filters.filters[FILTERS_NAMES.SORT]}
        onChange={filters.setFilter}
      />
    );
  }

  if (isMain) {
    return user?.sub ? <AmountFilter filters={filters} listKey={ListKeys.LIST} /> : <PhoneNumberChecker />;
  }

  return <AmountFilter filters={filters} listKey={ListKeys.LIST} />;
};

import add from 'date-fns/add';
import { useRouter } from 'next/router';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

import { sendPhoneNumberEvent } from '@src/helpers/analyticsEvents';
import { useFakePixelClickLink } from '@src/hooks/useFakePixelClickLink';
import { Cookie, CookieKey } from '@src/modules/cookie';
import { useSelectUser } from '@src/reducers/user';

import { saveUserPhone } from '../model';

const SAVE_PHONE_ERROR_TEXT = 'Не удалось загрузить данные';

interface Props {
  setErrorMessage: Dispatch<SetStateAction<string>>;
}

export const useSaveUserPhone = ({ setErrorMessage }: Props) => {
  const router = useRouter();
  const userId = useSelectUser()?.sub || null;
  const { fetchFakePixelClick } = useFakePixelClickLink();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const onSave = async (phone: string) => {
    setIsSubmitDisabled(true);

    try {
      await saveUserPhone({ phone, userId });
      sendPhoneNumberEvent();
      router.reload();
    } catch (error) {
      setErrorMessage(SAVE_PHONE_ERROR_TEXT);
    }

    Cookie.set(CookieKey._PN_, phone, { expires: add(new Date(), { minutes: 10 }) });
    fetchFakePixelClick();

    setIsSubmitDisabled(false);
  };

  return { isSubmitDisabled, onSave };
};

/**
 * Задача MFO-1711
 *
 * Получи заём: 6745922fc7a5e60029c5b780, 6734b8eba871da0027300346
 * СКБ-Финанс: 67890a24b88e200026a16f27
 * МаниМен: 6788ef47173156002849d4a5
 * МигКредит: 6788f71eb88e200026a16f21
 * Веббанкир: 6788f1b8173156002849d4a9
 */
const OFFERS_WITH_INSTANT_REDIRECT_TO_PS = [
  '6745922fc7a5e60029c5b780',
  '6734b8eba871da0027300346',
  '6788ef47173156002849d4a5',
  '6788f71eb88e200026a16f21',
  '6788f1b8173156002849d4a9',
];

export const isOfferWithBottomSheetCalculator = (offerId: string) =>
  !OFFERS_WITH_INSTANT_REDIRECT_TO_PS.includes(offerId);

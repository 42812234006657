const MOBILE_NUMBER_CHAR_COUNT = 12;

export const validatePhoneNumber = (phone: string, phoneNumberValidate: (phone: string | number) => boolean) => {
  if (phone.length === 0) {
    return { isValid: false, errorMessage: 'Заполните поле' };
  }

  if (phone.length !== MOBILE_NUMBER_CHAR_COUNT || !phoneNumberValidate(phone)) {
    return { isValid: false, errorMessage: 'Введите корректный номер телефона' };
  }

  return {
    isValid: true,
    errorMessage: '',
  };
};

import type { FC } from 'react';
import React from 'react';

import { PhoneNumberCheckerForm } from './PhoneNumberCheckerForm';
import { PhoneNumberCheckerLayout } from './PhoneNumberCheckerLayout';

interface Props {
  termsLink?: string;
  phoneNumberValidate?(phone: string | number): boolean;
}

export const PhoneNumberChecker: FC<Props> = ({ termsLink, phoneNumberValidate }) => (
  <PhoneNumberCheckerLayout termsLink={termsLink}>
    <PhoneNumberCheckerForm phoneNumberValidate={phoneNumberValidate} />
  </PhoneNumberCheckerLayout>
);

import { useRouter } from 'next/router';
import { useMemo } from 'react';

import type { IPublicUser } from '@sravni/types/lib/auth';
import type { Seo } from '@sravni/types/lib/seo';

import { FILTERS_NAMES } from '@src/constants/filters';
import type { IFilter } from '@src/hooks/useFilters';
import { FullDealEntryPoint } from 'widgets/FullDealEntryPoint';
import { useIsShowFullDealEntrypoint } from 'widgets/FullDealEntryPoint/lib';
import { ProductListFilterSortPanel } from 'widgets/ProductListFilterSortPanel';

import { getListPageFilterComponent } from './getListPageFilterComponent';

export const useGetHeaderFilters = (filter: IFilter, seo: Partial<Seo>, user: IPublicUser) => {
  const { asPath, query } = useRouter();
  const [pathWithoutQuery] = asPath.split('?');

  const isShowFullDealEntrypoint = useIsShowFullDealEntrypoint();

  return useMemo(() => {
    if (isShowFullDealEntrypoint) {
      return {
        Filters: (
          <>
            <FullDealEntryPoint heading={seo?.heading} />
            <ProductListFilterSortPanel
              initialAmount={filter.filters[FILTERS_NAMES.AMOUNT]}
              initialTerm={filter.filters[FILTERS_NAMES.TERM]}
              sorting={filter.filters[FILTERS_NAMES.SORT]}
              onChange={filter.setFilter}
            />
          </>
        ),
        isShowFullDealEntrypoint,
      };
    }

    return {
      Filters: getListPageFilterComponent(pathWithoutQuery, query, filter, user),
      isShowFullDealEntrypoint,
    };
  }, [filter, pathWithoutQuery, isShowFullDealEntrypoint, seo, user, query]);
};
